// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

hr {
  //border-top:1px solid $color-text-light;
  height: 2px;
  border-style: solid;
  border-width: 0.5px 0 1px 0;
  border-color: $color-bg-lightest;

}

.section {
  padding: 3rem 0;
}

.bg-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar {
  .navbar-brand {
    margin-right: 0;

    img {
      min-height: 150px;
      @include media-breakpoint-down(sm) {
        min-height: 90px;
        width: auto;
      }
    }
  }

  .cart {
    width: 32px;

    .cart-number {
      width: 20px;
      height: 20px;
      display: block;
      text-align: center;
      background-color: $color-primary;
      color: white;
      border-radius: 50%;
      line-height: 20px;
      vertical-align: middle;
      font-weight: 600;
      font-size: 10px;

      position: absolute;
      top: -4px;
      left: -9px;
    }
  }

  #mobile-cart{
    // position: absolute;
    // left: 85vw;
    width: unset;
  }

  .navbar-toggler {
    // position: absolute;
    // left: 10vw;
    width: 35px;
    height: 35px;
    padding: 0;

    .navbar-toggler-icon {
      width: 100%;
      height: 100%;
    }
  }

  .nav {
    .nav-item {
      .nav-link {
        color: white !important;
        background-color: $color-primary;
        font-weight: 300;
        border: 0.5px solid $cyan;
        line-height: 3;

        &:hover {
          background-color: $color-light;
          color: $color-primary !important;
        }

        &.active {
          color: white;
          background-color: $cyan;
        }
      }

      .dropdown {
        background-color: $color-dark;
        border-radius: 0;
        min-width: max-content;
        text-align: left;
        width: 100%;
        margin-left: 0px;
        padding-top: 5px;
        padding-bottom: 5px;

        .nav-link {
          text-transform: uppercase;
          padding: 5px 15px;
          border: none;
          color: $color-light;
          background-color: $color-dark;
          line-height: 1;

          &:hover {
            background-color: $color-light;
            color: $color-dark;
          }
        }

      }
    }
  }
}
.navbar-mobile{
  @include media-breakpoint-down(md) {
    background-color: $color-primary !important;
    .nav{
      .nav-item{
        .nav{
          .nav-item{
            .nav-link{
              font-size: 1rem !important;
            }
          }
        }
        .nav-link{
          border: none;
          border-bottom: 0.5px solid $cyan;
          font-size: 1.2rem !important;
        }
      }
    }
  }
}

.main-content {
  margin-top: 320px;
  min-height: 40vh;
  @include media-breakpoint-down(md){
    margin-top: 250px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 150px;
  }
}

.single {
  .page-title {
    display: flex;
    align-items: center;

    h1 {
      min-width: fit-content;
    }

    &:after {
      content: "";
      margin-left: 10px;
      height: 4px;
      border-top: 2px solid $color-primary;
      border-bottom: 1px solid $color-primary;
      width: 100%;
      display: flex;
    }
  }
}

.slick-slider {
  .slick-arrow {
    background-color: transparent;
    margin: 0px 15px;

    &:before {
      width: 20px;
      height: 20px;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    &:hover {
      &:before {
        border-right: 1px solid rgba(255, 255, 255, 1);
        border-bottom: 1px solid rgba(255, 255, 255, 1);
      }
    }
  }
}

.sidebar {
  .nav {
    .nav-item {
      border-bottom: 1px solid $color-text-lighter;

      .nav-link {
        color: inherit;
        font-weight: 300;
        text-transform: uppercase;
        padding: 10px 0;

        &.active {
          color: $color-primary;
          font-weight: 400;
        }
      }
    }

  }
}

.product-categories, .product-tags {
  &.card {
    border: 1px solid $color-bg-lightest;
    border-radius: 0;

    .card-header {
      background-color: transparent;

      h3 {
        letter-spacing: -0.5px;
      }

      hr {
        margin: 15px 0;
      }
    }
  }
}

.product-tags {
  .tag {
    display: inline-block;
    background-color: $color-bg-light;
    padding: 3px 5px;
    margin-bottom: 4px;

    &:hover {
      background-color: $color-primary;
      color: $color-light;
    }
  }
}

.product-card {
  .image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    padding-bottom: 100%;
  }

  .stamp {
    position: absolute;
    top: -15px;
    right: -15px;
    display: flex;

    .stamp-item {
      display: flex;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      color: white;
      font-size: 12px;
      background-color: $dark;
      text-align: center;
      text-transform: uppercase;
      margin: 5px;
      padding: 5px;
      align-items: center;
      justify-content: center;
    }
  }
}


.main-footer {
  .nav-cards {
    .nav-link {
      opacity: 0.5;

      &:hover {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }
    }
  }
}

.builder {
  .section {
    padding: 1rem 0;
  }

  .accordion {
    .card {
      background-color: transparent;
      border: 1.5px solid $color-text-light !important;
      border-radius: 0;
      margin-bottom: 8px;

      .card-title {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.5px;
        background-color: transparent;
      }

      .card-body {
        border-top: 1.5px solid $color-text-lightest;

        strong {
          color: $color-primary;
        }
      }
    }
  }

  .hero-slider {
    padding-top: 0;

    .bg-image {
      min-height: 500px;
      height: 50vh;
      width: 100vw;
      @include media-breakpoint-down(md) {
        min-height: unset;
        max-height: 300px;
        height: 50vh;
      }
      @include media-breakpoint-down(xs){
        height: 35vh
      }
      @media (max-width: 400px){
        height:30vh;
      }
    }
  }

  .specials {
    .slick-slider {
      .slick-arrow {
        margin: 0;

        &:before {
          width: 20px;
          height: 20px;
          border-right: 1px solid rgba(0, 0, 0, 0.3);
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }

        &:hover {
          &:before {
            border-right: 1px solid rgba(0, 0, 0, 0.9);
            border-bottom: 1px solid rgba(0, 0, 0, 0.9);
          }
        }
      }

      .slide {
        min-height: 520px;
        display: inline-grid !important;
        @include media-breakpoint-down(md) {
          min-height: 450px;
        }
      }
    }
  }

  .brands-slider {
    .slide {
      height: 150px;

      .image {
        max-height: 100%;
      }
    }
  }

  .text {
    .content {
      color: $color-text-dark;
      font-size: 1.2rem;
      font-weight: 400;

      img {
        float: right;
      }
    }
  }
}


.store {
  .store-title {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.75rem;
      font-weight: 500;
      min-width: fit-content;
    }

    &:after {
      content: "";
      margin-left: 10px;
      height: 4px;
      border-top: 2px solid $color-primary;
      border-bottom: 1px solid $color-primary;
      width: 100%;
      display: flex;
    }
  }

  .store-contact {
    font-size: 1.75rem;
  }

  .store-hours, .store-description {
    font-size: 1.25rem;
  }

  .store-images {
    .slide {
      .bg-image {
        height: 700px;
      }
    }
  }

  .store-map {
    min-height: 400px;
  }
}

//----------------------------------------
// Product Page style
//----------------------------------------
.product {
  .badge {
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 16px;
    padding: 8px 20px;
    margin-bottom: 8px;
  }
  .form {
    .form-control {
      font-size: 1.2rem;
      padding: 15px;
      line-height: 1;
      height: auto;
    }
    select {
      width: max-content;
      min-width: calc(50% - 1rem);
    }
  }

  .description{
    font-size: 1rem;
    p{
      font-size: 1rem;
      margin-bottom: 15px;
    }
  }
}

#commerce-cart {
  .cart-heading, .cart-row{
    border: 1px solid $color-bg-light;
    border-collapse: collapse;
  }
  .sale-name {
    color: $color-primary;
  }

  .sale-price {
    font-weight: 600;
    font-size: 1.2*$font-size-base;
    color: $color-warning;
  }
}

//----------------------------------------
// Checkout style
//----------------------------------------
.checkout {
  .btn {
    font-size: 1rem;
    padding: 15px 20px;
  }

  .cart-summary {
    td {
      padding: 8px;
    }

    h6 {
      margin: 0;
      font-size: 1rem;
      line-height: 1;
    }
  }

  .border-highlight {
    border: 4px double $color-primary;
  }

  .shipping-select {
    .form-check-input {
      margin-top: 0.7rem;
    }

    label {
      padding: 10px 15px;
    }

    input[type="radio"]:checked + label {
      border: 1px solid $color-warning;
    }
  }
}


//Form Style

.form-control {
  color: $color-bg-dark;
  font-weight: 400;
  background-color: $color-bg-lightest;
  border-radius: 0;
  padding: 8px 12px;

  &::placeholder {
    font-weight: 300;
    color: $color-text;
  }
}

.qty {
  display: flex;
  input[type="number"] {
    margin: 0 2px;
    text-align: center;
    width: max-content;
    @include media-breakpoint-down(md){
      width: 50px;
    }
  }
  .btn{
    font-size: 1.5rem;
    font-weight: 300;
    padding: 0 15px;
    background-color: $color-bg-lightest;
  }
}

.sorting {
  width: max-content;
}

.card-radio {
  .card {
    &:hover {
      cursor: pointer;
    }
  }

  .card-input-element:checked + .card {
    border: 2px solid $color-primary !important;
  }
}

select.form-control {
  padding: 0 8px;
}

.address-data {
  padding-left: 10px;
  margin: 0;
  list-style: none;

}

.user-photo{
  max-width: 250px;
  max-height: 250px;
}

//----------------------------------------
// Stripe Form
//----------------------------------------
.stripe-payment-intents-form, .stripe-charge-form {
  width: 100% !important;
}

.stripe-form {
  width: 100% !important;
  @media (max-width: 992px) {
    width: 100% !important;
  }
}

.card-errors, .info-errors {
  color: $red;
}

.StripeElement {
  width: 100%;
  height: 45px;
  margin-top: 30px;
  padding: 0.8rem .75rem;
  border: 1px solid $color-warning;
}

//----------------------------------------
// Cart Holder
//----------------------------------------
.card-holder {
  legend {
    font-size: 1rem;
  }

  .grid, .card-data {
    display: flex;
    justify-content: space-between;
    width: 100%;

    div {
      width: 48%;

      input {
        display: block;
        width: 100%;
        height: calc(1.9em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: .9375rem;
        font-weight: 300;
        line-height: 1.9;
        color: #777;
        background-color: white;
        background-clip: padding-box;
        border: 1px solid $color-text-light;
        border-radius: 2px;
        transition: all 0.3s ease-out;
      }
    }
  }

  .card-data {
    margin: 10px 0;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      div {
        width: 48%;
      }
    }
  }
}